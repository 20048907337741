<script lang="ts">
	import Carousel from "@components/common/Carousel.svelte";
	import Media from "@components/common/Media.svelte";
	import autoScroll from "embla-carousel-auto-scroll";
	import type { Snippet } from "svelte";
	import type { LogoProps } from "../data/getReferences.js";
	import { Background } from "./Background.js";

	const {
		headline,
		children,
		logos: logoPanels,
	}: {
		headline?: Snippet;
		children?: Snippet;
		logos: LogoProps[][];
	} = $props();

	/* eslint-disable @typescript-eslint/no-unsafe-assignment */
</script>

<section class={["full-width", Background.White]}>
	{#if headline}
		<h2 class="max-w-xl xl:max-w-3xl mx-auto text-center mb-8 md:mb-20">
			{@render headline()}
		</h2>
	{/if}
	{#each logoPanels as logos, index (index)}
		<Carousel
			class="border-t-4 border-fstlight-100"
			items={logos}
			type="flex"
			options={{
				loop: true,
				watchDrag(): boolean {
					return false;
				},
			}}
			plugins={[
				autoScroll({
					speed: 1,
					direction: index % 2 === 0 ? "forward" : "backward",
				}),
			]}
		>
			{#snippet item({ src, alt, width, height })}
				<div
					class="basis-[33.33%] lg:basis-[20%] xl:basis-[16.67%] h-24 lg:h-36 flex items-center justify-center border-r-4 border-fstlight-100"
				>
					<Media
						class="object-contain p-3 lg:p-0"
						source={{ src, type: "image/svg+xml" }}
						{width}
						{height}
						alt={alt ?? ""}
					/>
				</div>
			{/snippet}
		</Carousel>
	{/each}
</section>

{@render children?.()}
